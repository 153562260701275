/*------------------------------------*\
  Dropzone
\*------------------------------------*/

.bp-dropzone {
    position: relative;
    width: 100%;
    padding: 0 10px;
    min-height: 50px;

    &--full {
        height: 100%;
    }

    &--preview {
        padding: 0;
    }
}

.bp-dropzone__main {
    position: relative;
}

.bp-dropzone__grid {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 10px;
    right: 10px;
    bottom: 0;
    display: flex;
    pointer-events: none;
}

.bp-dropzone__column {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .1);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 3px;
        height: 100%;
        background: rgba( 255, 0, 0, 1);
        opacity: 0;
    }

    &--active:before {
        opacity: 1;
    }
}