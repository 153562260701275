/*------------------------------------*\
  Block
\*------------------------------------*/

.bp-block-wrapper {
    --block-offset-left: 0;
    --block-height: 40px;
    --block-width: 90%;

    --offset:  0;
    --colspan:  0;
    --col-width: calc((100%) / var(--colspan));
    --offset-size: calc((var(--col-width) * var(--offset)));

    &--offset {
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: calc( -1 * (var(--offset-size) - var(--gutter-size)));
            width: var(--offset-size);
            height: 2px;
            transform: translateY(-50%);
            background: #cecece;
        }

        &:after {
            position: absolute;
            content: '';
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            width: 10px;
            height: 10px;
            right: calc(100% - var(--gutter-size));
            border-top: 2px solid #cecece;
            border-right: 2px solid #cecece;
        }
    }

    &--preview {
        margin-left: var(--block-offset-left);
        min-height: var(--block-height);
        width: var(--block-width);
        transition: .25s ease min-height,
                    .25s ease margin-left,
                    .25s ease padding;

        &.bp-block-wrapper--offset:before {
            left: calc( -1 * (var(--offset-size)));
            transition: .25s ease  width,
            .25s ease left;
        }

        &.bp-block-wrapper--offset:after {
            right: 100%;
            transition: .25s ease right;
        }

        &:hover {
            &.bp-block-wrapper--offset:before {
                width: calc(var(--offset-size) - 40px);
            }

            &.bp-block-wrapper--offset:after {
                right: calc(100% + 40px);
            }
        }

        &:hover > .resizable > .bp-block--preview:not(.bp-block--no-hover) {
            &:before {
                opacity: 1;
            }

            > .bp-block__header {
                opacity: 1;
                pointer-events: initial;
            }

            &.bp-block--has-children {
                margin-top: -40px;
                margin-left: -40px;
                margin-right: -40px;
                margin-bottom: -40px;
                height: calc(100% + 80px);
                width: calc(100% + 80px);
                padding: 40px;
            }
        }
    }
}

.bp-block {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 2px solid rgba(0,0,0,.2);
    transition: .25s ease opacity,
                .25s ease border;
    max-height: 100%;

    &--dragging {
        opacity: .5;
    }

    &--full-width {
        margin-left: -10px;
        margin-right: -10px;
    }
}

.bp-block--preview {
    position: relative;
    height: 100%;
    width: 100%;
    max-height: initial;
    margin: 0;
    border: none;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 3px solid rgba( 62, 157, 236, .5);
        opacity: 0;
        transition: .25s ease opacity;
    }

    &.bp-block--has-children {
        transition: .25s ease margin,
        .25s ease height,
        .25s ease width,
        .25s ease padding;
    }

    &.bp-block--dragging,
    &.bp-block--active {
        &:before {
            opacity: 1;
        }

        > .bp-block__header {
            opacity: 1;
            pointer-events: initial;
        }

        &.bp-block--has-children {
            margin-top: -40px;
            margin-left: -40px;
            margin-right: -40px;
            margin-bottom: -40px;
            height: calc(100% + 80px);
            width: calc(100% + 80px);
            padding: 40px;
        }
    }

    &.bp-block--dragging {
        .bp-block__header {
            background-color: rgba( 62, 157, 236,.8);
        }
    }
}

.bp-block__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0,0,0,.2);
    color: var(--text-on-primary);
    padding: 5px;

    &--preview {
        position: absolute;
        top: 3px;
        right: 3px;
        align-items: flex-start;
        background-color: rgba( 62, 157, 236,.5);
        opacity: 0;
        pointer-events: none;
        font-weight: normal;
        font-size: 18px;
        line-height: 1.2rem;
    }
}

.v-application a.bp-block__options,
.bp-block__options {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    color: white;
    width: 25px;
    height: 25px;
    text-decoration: none;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
        background: white;
        opacity: 0;
        transition: .27s ease opacity;
    }

    &:hover {
        &:before {
            opacity: .2;
        }
    }

    &--preview {
        justify-content: center;
        margin-left: 5px;
    }
}

.bp-block__offset-remove {
    position: absolute;
    top: 5px;
    right: 5px;
}