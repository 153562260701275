/*------------------------------------*\
  Block type list
\*------------------------------------*/

.bp-block-type-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bp-block-type-list__items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.bp-block-type {
    flex: 0 0 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    transition: .3s ease background;

    &:hover,
    &:focus,
    &:active {
        background: rgba(0,0,0,0.2);
    }

    &--dragging {
        opacity: .5;
    }
}

.bp-block-type__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}