/*------------------------------------*\
  Resizable
\*------------------------------------*/

.resizable-wrapper {
    position: relative;
}

.resizable {
    position: relative;
    resize: none;
    height: 100%;
}

.resizable__handle {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 8px;
    cursor: col-resize;
    text-indent: -9999px;
    overflow: hidden;
    border: none;
    background: none;
    z-index: 4;

    &--left {
        left: 0;
    }

    &--right {
        right: 0;
    }
}