@import "partials/block";
@import "partials/dropzone";
@import "partials/resizable";
@import "partials/tabs";
@import "partials/dropdown";
@import "partials/block-type-list";
@import "partials/preview";
@import "partials/breakpoint";

.bp-blueprint {
    --cols: 12;
    --gutter-size: 15px;

    display: flex;
    flex-direction: column;
    height: 100%;
}

.bp-blueprint__actions {
    position: fixed;
    z-index: 1;
    right: 0;
    bottom: 15px;
    display: flex;
    padding: 10px 10px;
    background: white;
    box-shadow: 0 0 20px rgba( 0, 0, 0, .3);

    .v-btn {
        margin: 5px;
    }
}

.bp-contianer {
    position: relative;
}